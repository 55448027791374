<template>
<div class="container font-open-sans">
    <br>
    <div><strong class="fs-20 mb-10">Practice History</strong></div>
    <br>
    <div class="row">
            <div class="col l3 m6 s12 pl-0 input-box">
                <select name="" id="select-input" class="browser-default pointer" @change="selectSubject($event)" v-model="selectedSubjectId">
                    <option value="All" disabled selected>Filter By Subject</option>
                    <option value="0" selected>All Subjects</option>
                    <option v-for="subject in subjectFilters" :key="subject.subjectId" :value="subject.subjectId">
                          {{ subject.subjectName }}
                      </option>
                </select>
            </div>
        </div>
    <div class="scroll-verticle-bar scroll-bar" v-if="listLength > 0">
        <div class="practice-details row s12 m12" v-for="data in practiceList" :key="data.UserTestSessionId">
            <div class="m-0">
                <div class="title-row flex flex-between items-center display-block">
                    <div class=" fs-16 fw-600 truncate" :title="data.Chapters">{{ data.Chapters }}</div>
                    <div class=" flex d-block margin-tb-10-1120">
                        <div class="flex">
                            <img :src="require(`../assets/testHistory-calendar.svg`)" class="icon-bullet">
                            <span class="pl-5">{{formatDate(data.StartedOn)}}</span>
                        </div>
                        <div class="flex pl-25 padding-l-0">
                            <img :src="require(`../assets/testHistory-time.svg`)" class="icon-bullet">
                            <span class="pl-5">{{minuteSecondHandler(data._TotalTimeTaken)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row flex m-0 display-block">
                <div class="col s12 m6 l4 padding-lr-20 m-0 border-right">
                    <div class="row mb-15 margin-bottom-0">
                        <div class="col m5 s12 text-div p-0 margin-bottom-10">
                            <img :src="require(`../assets/testHistory-books.svg`)" class="icon-bullet">
                            <span class="fw-500 fs-14 pl-5">{{ data.SubjectName !== undefined ? formatString(data.SubjectName) : null}}</span>
                        </div>
                        <div class="col m7 s12 text-div p-0 truncate margin-bottom-10" :title="data.Topic">
                            <img :src="require(`../assets/testHistory-openBook.svg`)" class="icon-bullet">
                            <span class="fw-500 fs-14 pl-5">{{data.TopicName !== undefined ? formatString(data.TopicName) : null}}</span>
                        </div>
                    </div>
                    <div class="text-div row s12 m12 m-0 margin-bottom-10" :title="data.Chapter">
                        <img :src="require(`../assets/testHistory-bookmark.svg`)" class="icon-bullet">
                        <span class="fw-500 fs-14 pl-5">{{ data.Chapters !== undefined ? formatString(data.Chapters) : null}}</span>
                    </div>
                </div>
                <div class="col s12 m6 l4 row m-0 display-block padding-0-600 padding-l-20">
                    <div class=" flex-direction-align-center col s3 m3 pr-10 pl-0 flex-align-start" :title="`Total: ${data._TotalQuestions}`">
                        <div class="text-div">
                            <img :src="require(`../assets/total12.svg`)" class="icon-bullet">
                        </div>
                        <div class="c-blue fw-600 fs-16 left-align-wht">
                            {{ data._TotalQuestions}}
                        </div>
                    </div>
                    <div class="flex-direction-align-center col s3 m3 pr-10 pl-0 flex-align-start" :title="`Attempted: ${data._TotalAttempted}`">
                        <div class="text-div">
                            <img :src="require(`../assets/total12.svg`)" class="icon-bullet">
                        </div>
                        <div class="c-blue fw-600 fs-16 left-align-wht">
                            {{data._TotalAttempted !== null ? data._TotalAttempted : "0"}}
                        </div>
                    </div>
                    <div class="flex-direction-align-center col s3 m3 pr-10 pl-0 flex-align-start" :title="`Correct: ${data._TotalCorrect}`">
                        <div class="text-div">
                            <img :src="require(`../assets/correct13.svg`)" class="icon-bullet">
                        </div>
                        <div class="c-green fw-600 fs-16 left-align-wht">
                            {{data._TotalCorrect !== null ? data._TotalCorrect : "0"}}
                        </div>
                    </div>
                    <div class="flex-direction-align-center col s3 m3 pr-10 pl-0 flex-align-start" :title="`Incorrect: ${data._TotalAttempted - data._TotalCorrect}`">
                        <div class="text-div">
                            <img :src="require(`../assets/wrong13.svg`)" class="icon-bullet">
                        </div>
                        <div class="c-red fw-600 fs-16 left-align-wht">
                            {{ data._TotalAttempted - data._TotalCorrect }}
                        </div>
                    </div>
                </div>
                <div class="col s12 m12 l3 btn-last mb-15 text-align-left">
                    <div class="padding-lr-20">
                        <a class="waves-effect waves-light testHLinkButton btn" @click="viewReport(data.UserTestSessionId)">View Report</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="text-center error-box">
        <img :src="require(`../assets/no-result-found.png`)" alt="" style="width: 200px; opacity: 0.7;">
        <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585;">No practice sessions available.</p>
    </div>
    <div v-if="listLength > 0" class="pagination flex flex-between d-block">
        <div>
            <span class="show-index fw-500 fs-15  ws-nowrap ">
                <span>Showing </span>
                <span>{{startPageNumber}}</span>
                <span> - </span>
                <span>{{endPageNumber}}</span>
                <span> of </span>
                <span>{{ totalQnsList}}</span>
                <span> Sessions</span>
            </span>
        </div>
        <div class="flex flex-wrap margin-bottom-10-325" style="gap: 3px;">
            <a @click="previousPage()" class="navigation-btn prev-btn" :class="{disable: currentPage < 2}" title="Previous">
                <span class="material-icons material-symbols-outlined fs-20 pointer">west</span>
            </a>
            <a @click="nextPage()" class="navigation-btn" :class="{disable: listLength < 20 }" title="Next">
                <span class="material-icons material-symbols-outlined fs-20 pointer">east</span>
            </a>
        </div>
    </div>
</div>
</template>

<script>
import moment from "moment";
import {
  useToast,
} from "vue-toastification";
import MobileAPI from "../Mobileapi";

const toast = useToast();

export default {
  data() {
    return {
      practiceList: [],
      typeTest: 1,
      courseId: null,
      getUser: null,
      startEnable: false,
      userId: null,
      affiliationUserName: null,
      currentPage: 1,
      listLength: 0,
      totalQnsList: 0,
      startPageNumber: 1,
      endPageNumber: 20,
      totalCount: 0,
      next: true,
      filterTriggered: false,
      subjectList: [],
      subjectFilters: [],
      orginalApiData: "",
      selectedSubjectId: null,
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  created() {
    this.reset();
  },
  methods: {
    nextPage() {
      this.next = true;
      this.currentPage += 1;
      this.startPageNumber = this.endPageNumber + 1;
      this.endPageNumber += 20;
      this.getPracticeDataList();
    },
    previousPage() {
      this.next = false;
      this.currentPage -= 1;
      this.endPageNumber -= this.listLength;
      this.startPageNumber -= 20;
      this.getPracticeDataList();
    },
    getPracticeDataList() {
      const dataList = {
        PageNo: this.currentPage,
        SubjectId: this.selectedSubjectId,
      };
      this.$store.dispatch("showLoader", true);
      if (!this.next) {
        this.totalCount -= this.listLength;
      }
      MobileAPI.getPracticeHistoryNew(dataList, (resp) => {
        if (resp.responseCode === 200) {
          if (resp.data.length > 0) {
            this.$store.dispatch("showLoader", false);
            this.listLength = 0;
            this.practiceList = resp.data;
            this.orginalApiData = resp.data;
            if (this.subjectFilters.length <= 0) {
              const subjectFilter = this.practiceList.map((item) => ({
                subjectName: item.SubjectName,
                subjectId: item.SubjectId,
              }));
              this.subjectFilters = [...new Map(subjectFilter.map((item) => [item.subjectName, item])).values()];
            }
            if (this.next) {
              this.totalCount += resp.data.length;
            }
            this.totalQnsList = this.practiceList[0].TotalTest;
            if (this.totalCount === this.totalQnsList) {
              this.endPageNumber = this.totalCount;
            }
            this.listLength = this.practiceList.length;
          } else {
            toast.error("No practice history found", {
              timeout: 2500,
            });
            this.$store.dispatch("showLoader", false);
          }
        } else {
          toast.error(`${resp.data.info}`, {
            timeout: 2500,
          });
          this.$store.dispatch("showLoader", false);
        }
      });
    },
    selectSubject(event) {
      this.selectedSubjectId = Number(event.target.value);
      if (this.selectedSubjectId === 0) { this.selectedSubjectId = null; }
      this.getPracticeDataList();
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).utcOffset("-00:00").format("DD/MM/YYYY hh:mm A");
      }
      return "";
    },
    viewReport(practiceId) {
      this.$router.push({
        name: "PracticeSessionNewReport",
        params: {
          UserTestSessionId: Number(practiceId),
          TestName: "PracticeHistory",
        },
      });
    },
    formatString(string) {
      const str = string.split(",");
      if (str.length === 1) {
        if (str[0].length > 20) {
          str[0] = str[0].slice(0, 20);
          return `${str[0]}..`;
        }
        return str[0];
      }
      if (str[0].length > 20) {
        str[0] = str[0].slice(0, 20);
      }
      return `${str[0]}... +${str.length - 1}`;
    },
    minuteSecondHandler(value) {
      if (value) {
        const hour = Math.floor(value / 3600);
        const minute = Math.floor((value % 3600) / 60);
        const seconds = Math.floor(value % 60) || "";
        // console.log("date", hour, minute, seconds);
        if (value > 59) {
          return (hour >= 1 ? ` ${hour}h` : "") + (minute >= 1 ? ` ${minute}m` : "") + (seconds >= 1 ? ` ${seconds}s` : "");
        }
        return `${Math.floor(value)}s`;
      }
      return "0s";
    },
    reset() {
      const user = this.$store.getters.user;
      const userId = Number(user.UserId);
      this.$store.dispatch("showLoader", true);
      MobileAPI.getStudentDetails(userId, (response) => {
        if (Object.keys(response.data).length !== 0 || response.data !== null) {
          const student = response.data;
          this.courseId = student.courseId;
          this.studentId = student.studentId;
          this.getPracticeDataList();
        } else {
          toast.error(`${response.info}`, {
            timeout: 2500,
          });
          this.$store.dispatch("showLoader", false);
        }
      });
    },
  },
};
</script>

<style scoped>
.ml-0 {
    margin-left: 0% !important;
}

.scroll-bar {
    max-height: calc(100vh - 270px);
}

.error-box {
    margin-bottom: 20px;
}

.pagination {
    padding: 13.15px;
    background-color: #FFFFFF;
    align-items: center;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pagination {
    margin-bottom: 10px;
}

.prev-btn {
    margin-right: 20px;
}

.show-index {
    color: #333333;
}

.br-4 {
    border-radius: 4px;
}

.padding-lr-20 {
    padding: 0px 20px !important;
}

.border-right {
    height: max-content;
    border-right: 1px solid #D0D0D0;
}

.pl-25 {
    padding-left: 25px !important;
}

.p-20 {
    padding: 20px !important;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.btn-last {
    text-align: end;
    margin-top: 35px;
}

.title-row {
    margin-right: 14px;
    padding: 15px 20px;
}

.font-open-sans {
    font-family: 'Open Sans';
    font-style: normal;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.m-0 {
    margin: 0 !important;
}

.c-blue {
    color: #3751FF;
}

.c-green {
    color: #16A085;
}

.c-red {
    color: #EF727A;
}

.mb-10 {
    margin-bottom: 8px;
}

.radio-btn {
    color: black;
    font-weight: 400;
    margin-right: 10px;
    margin-top: 8px;
}

.icon-bullet {
    width: 24px;
}

.flex-justify-space-around {
    display: flex;
    justify-content: space-around;
}

.flex-direction-align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-5 {
    padding-left: 5px;
}

.pl-25 {
    padding-left: 25px !important;
}

.pr-10 {
    padding-right: 10px;
}

.text-div {
    display: flex;
    align-items: center;
}

.text-500 {
    font-weight: 500px;
    font-size: 14px;
}

.test {
    background-color: #338510;
}

.practice-details {
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
}

.submit-btn {
    gap: 20px;
    display: flex;
}

.browser-default {
    background: #F3F5FC;
    border-radius: 4px !important;
    border-color: #F3F5FC !important;
    height: 34px;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:before,
[type="radio"].with-gap:checked+span:after {
    border: 2px solid #3751FF;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:after {
    background-color: #3751FF;
}

.lblTest {
    margin-left: 10px;
}

.testHLinkButton {
    width: 139px;
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
    border-radius: 5px;
    text-transform: uppercase;
    border: 1px solid #FF9421;
    height: 35px;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 600px;
    cursor: pointer;
}

.testHLinkButton:hover {
    cursor: pointer;
}

.getbutton {
    background: linear-gradient(90.2deg, #3751FF 0.16%, #3751FF 98.41%);
    border-radius: 5px;
    width: 150px;
}

.scroll-verticle,
.scroll-verticle-bar {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    white-space: nowrap !important;
}

.scroll-verticle::-webkit-scrollbar {
    width: 0px;
}

.scroll-verticle-bar::-webkit-scrollbar {
    width: 5px;
}

.scroll-verticle::-webkit-scrollbar-track,
.scroll-verticle-bar::-webkit-scrollbar-track {
    background-color: #ececec;
    border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb,
.scroll-verticle-bar::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.navigation-btn.disable {
    pointer-events: none;
}

.navigation-btn {
    width: 30px;
    height: 30px;
    line-height: 38px;
    text-align: center;
    background-color: white;
    color: #8c8585;
    border: 1px solid #333333 !important;
    border-radius: 3px;
}

.navigation-btn.currentPage {
    border-color: #3751FF !important;
    color: #3751FF;
}

.input-box{
    padding-right: 0 !important;
    }

    select{
    border: 1px solid #E0E4F0 !important;
    background-color: white !important;
    height: 43px;
    border-radius: 4px;
    font-size: 14px;
    color: #adadad;
}

    #select-input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url("../assets/Vector4.svg") no-repeat right;
      background-size: 38px 6px;
      height: 43px;
      padding-left: 7px;
}

@media screen and (max-width: 1120px) {
    .display-block {
        display: block !important;
    }

    .margin-tb-10-1120 {
        margin: 10px 0px !important;
    }
}

@media screen and (max-width: 600px) {
    .flex-align-start {
        align-items: flex-start;
    }

    .left-align-wht {
        width: 26px;
        height: 26px;
        text-align: center;
    }

    .padding-0-600 {
        padding: 0px !important;
    }

    .padding-l-20 {
        padding-left: 20px !important;
    }

    .margin-bottom-0 {
        margin-bottom: 0px !important;
    }

    .margin-bottom-10 {
        margin-bottom: 10px !important;
    }

    .text-align-left {
        text-align: left !important;
    }
}

@media screen and (max-width: 325px) {
    .d-block {
        display: block !important;
    }

    .padding-l-0 {
        padding-left: 0px !important;
    }

    .margin-bottom-10-325 {
        margin-bottom: 10px !important;
    }

    .ws-nowrap {
        white-space: nowrap !important;
    }
}
</style>
