<!-- eslint-disable no-continue -->
<template>
  <div>
    <div class="session-box practice-session">
      <div class="header-box relative" :style="`background: ${selectedQuestionData.BgColor + 20};`">
        <div class="header-options flex content-btw">
          <div class="header-right">
            <button class="header-right-button" @click="close()">
              <img :src="require(`../assets/button-arrow.svg`)" class="center-align" />
            </button>
            <div class="flex column content-btw" style="height: 59px">
              <span class="fw-700 fs-20 blk-text ln-27 chapter-name">{{
                selectedQuestionData.ChapterName
              }}</span>
              <span class="fw-400 fs-18 blk-text ln-18 flex class-name"
                >{{ selectedQuestionData.SubjectName }}
              </span>
            </div>
          </div>
          <div>
            <img
              :src="selectedQuestionData.DashboardIconUrl"
              style="opacity: 0.2; height: 72px; width: 75px; margin-top: -7px"
            />
          </div>
          <div class="header-left flex">
            <!-- <img v-if="testTypeId !== 2" style="margin-right:10px" class="button-size" :src="require(`../assets/pause-svg.svg`)" @click="pauseModal = true" /> -->
            <img
              style="margin-right: 10px"
              class="button-size"
              :src="require(`../assets/end-svg.svg`)"
              @click="close()"
            />
            <img
              style="margin-right: 10px"
              class="button-size"
              :src="require(`../assets/warn-svg.svg`)"
              @click="reportModal = true"
            />
          </div>
        </div>
      </div>
      <div v-if="conceptDiv === false">
        <div class="flex" style="justify-content: flex-end; margin-top: 8px; margin-bottom: 8px">
          <div
            v-if="isTutorHelp === 0"
            class="question-type-button"
            style="background: #ff835c !important; color: white !important"
          >
            Revise Question
          </div>
          <div
            v-else
            class="question-type-button"
            style="background: #ff835c !important; color: white !important"
          >
            Clarify Question
          </div>
          <div class="question-type-button">{{ selectedQuestionData.QuestionType }}</div>
        </div>
        <div v-if="questionLatexStatus === 'Y'" class="latex-section">
          <div v-if="questionLatex !== ''" class="row">
            <vue-mathjax
              :formula="questionLatex"
              :options="VueMathJaxOptions"
              :safe="false"
              class="space-break fs-18 fw-500"
            />
          </div>
          <div
            v-if="questionLatexDiagram !== null"
            class="row"
            :style="{
              'min-width': `${questionPosition[2] * 720}px`,
              'max-width': `${questionPosition[2] * 100}%`,
            }"
          >
            <img :src="getAzureUrl(questionDiagram)" />
          </div>
          <div class="row">
            <div
              class="col s6"
              style="display: flex; align-items: center"
              v-if="questionOptionA !== null"
            >
              <span class="selection-btn">A</span
              ><vue-mathjax
                :formula="questionOptionA"
                :options="VueMathJaxOptions"
                :safe="false"
                class="space-break fs-18 fw-500"
              />
            </div>
            <div
              class="col s6"
              style="display: flex; align-items: center"
              v-if="questionOptionB !== null"
            >
              <span class="selection-btn">B</span
              ><vue-mathjax
                :formula="questionOptionB"
                :options="VueMathJaxOptions"
                :safe="false"
                class="space-break fs-18 fw-500"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col s6"
              style="display: flex; align-items: center"
              v-if="questionOptionC !== null"
            >
              <span class="selection-btn">C</span
              ><vue-mathjax
                :formula="questionOptionC"
                :options="VueMathJaxOptions"
                :safe="false"
                class="space-break fs-18 fw-500"
              />
            </div>
            <div
              class="col s6"
              style="display: flex; align-items: center"
              v-if="questionOptionD !== null"
            >
              <span class="selection-btn">D</span
              ><vue-mathjax
                :formula="questionOptionD"
                :options="VueMathJaxOptions"
                :safe="false"
                class="space-break fs-18 fw-500"
              />
            </div>
          </div>
        </div>
        <div v-else class="image-section">
          <img :src="questionImage" class="question-image" style="" draggable="false" />
        </div>
        <div
          :class="{
            'answer-section': questionLatexStatus !== 'Y',
            'answer-section-latex': questionLatexStatus === 'Y',
          }"
        >
          <div v-if="isTutorHelp === 0">
            <div v-if="showOptions" class="answer-objective flex content-btw">
              <div
                class="flex content-btw answer-objective-div"
                style="max-width: 745px; width: 740px"
                :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
              >
                <div class="flex">
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedOption === 'A', deactive: answerGiven === 'A' }"
                    value="A"
                    @click="checkAnswer($event)"
                  >
                    A
                  </button>
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedOption === 'B', deactive: answerGiven === 'B' }"
                    value="B"
                    @click="checkAnswer($event)"
                  >
                    B
                  </button>
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedOption === 'C', deactive: answerGiven === 'C' }"
                    value="C"
                    @click="checkAnswer($event)"
                  >
                    C
                  </button>
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedOption === 'D', deactive: answerGiven === 'D' }"
                    value="D"
                    @click="checkAnswer($event)"
                  >
                    D
                  </button>
                </div>
                <div
                  class="flex content-cntr"
                  :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
                >
                  <hr class="answer-section-hr" style="margin-top: 24px" />
                  <button
                    class="submit-button fw-600 fs-13 ln-18"
                    :class="{ active: selectedOption !== '' }"
                    style="margin-top: 8px"
                    @click="submitAnswer()"
                  >
                    SUBMIT
                  </button>
                  <button
                    class="skip-button fw-600 fs-13 ln-18"
                    style="margin-top: 8px"
                    @click="skipQuestion()"
                  >
                    SKIP
                  </button>
                </div>
              </div>
              <div style="margin-top: 3px !important">
                <button
                  class="option-button center-align"
                  @click="showOther()"
                  style="background: #693383"
                >
                  <img class="option-img" :src="require(`../assets/threedots.svg`)" />
                </button>
              </div>
              <div
                v-if="showRevisionModal"
                class="revision-modal flex content-even"
                style="top: 0 !important; right: 0 !important"
              >
                <div class="flex flex-column content-even">
                  <button
                    v-if="isTutorHelp === 0"
                    class="flex content-left revision-button fs-13 fw-600"
                    @click="removeRevision()"
                  >
                    <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                    from Revision
                  </button>
                  <button
                    v-else
                    class="flex content-left revision-button-1 fs-13 fw-600"
                    @click="removeClarification()"
                  >
                    <img
                      class="revision-img-1"
                      :src="require(`../assets/problem-solution.svg`)"
                    />Remove for Clarification
                  </button>
                </div>
                <div class="flex flex-column content-cntr">
                  <button class="modal-close" @click="closeModal()">
                    <span class="material-icons" style="color: #ffffff !important">close</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="correctAnswer" class="correct-section flex content-btw relative">
              <div class="flex" :style="showRevisionModal ? 'filter: blur(2px) !important' : ''">
                <img
                  :src="require(`../assets/correct-answer.svg`)"
                  class="center-align correct-image"
                />
                <div class="flex column">
                  <span class="fw-700 fs-15 blue-text ln-20">Correct Answer !</span>
                </div>
              </div>
              <div
                class="flex relative"
                :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
              >
                <hr class="answer-section-hr" style="margin-top: 24px" />
                <button
                  class="concept-button fw-600 fs-13 ln-18"
                  style="margin-top: 8px"
                  @click="showSolution()"
                >
                  VIEW SOLUTION
                </button>
                <button
                  class="next-button fw-600 fs-13 ln-18"
                  style="margin-top: 8px"
                  @click="nextQuestion()"
                >
                  NEXT
                </button>
                <button
                  class="option-button center-align"
                  @click="showOther()"
                  style="margin-top: 3px !important; background: #693383"
                >
                  <img class="option-img" :src="require(`../assets/threedots.svg`)" />
                </button>
              </div>
              <div v-if="showRevisionModal" class="revision-modal flex content-even">
                <div class="flex flex-column content-even">
                  <button
                    class="flex content-left revision-button fs-13 fw-600"
                    @click="removeRevision()"
                  >
                    <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                    from Revision
                  </button>
                </div>
                <div class="flex flex-column content-cntr">
                  <button class="modal-close" @click="closeModal()">
                    <span class="material-icons" style="color: #ffffff !important">close</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="wrongAnswer" class="correct-section flex content-btw relative">
              <div class="flex" :style="showRevisionModal ? 'filter: blur(2px) !important' : ''">
                <img
                  :src="require(`../assets/wrong-answer.svg`)"
                  class="center-align correct-image"
                />
                <div class="flex column">
                  <span class="fw-700 fs-15 red-text ln-20">Wrong Answer !</span>
                  <span class="fw-400 fs-13 blk-text ln-18 wrong-text"
                    >Please review concepts and try again.</span
                  >
                </div>
              </div>
              <div
                class="flex relative"
                :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
              >
                <hr class="answer-section-hr" style="margin-top: 24px" />
                <button
                  class="concept-button fw-600 fs-13 ln-18"
                  style="margin-top: 8px"
                  @click="showConcept()"
                >
                  VIEW CONCEPT
                </button>
                <!-- <button class="concept-button fw-600 fs-13 ln-18" style="margin-top: 8px;" @click="showSolution()">VIEW SOLUTION</button> -->
                <button
                  class="concept-button fw-600 fs-13 ln-18"
                  style="margin-top: 8px"
                  @click="tryAgainQuestion()"
                >
                  TRY AGAIN
                </button>
                <button
                  class="next-button fw-600 fs-13 ln-18"
                  style="margin-top: 8px"
                  @click="nextQuestion()"
                >
                  NEXT
                </button>
                <button
                  class="option-button center-align"
                  @click="showOther()"
                  style="margin-top: 3px !important; background: #693383"
                >
                  <img class="option-img" :src="require(`../assets/threedots.svg`)" />
                </button>
              </div>
              <div v-if="showRevisionModal" class="revision-modal flex content-even">
                <div class="flex flex-column content-even">
                  <button
                    class="flex content-left revision-button fs-13 fw-600"
                    @click="removeRevision()"
                  >
                    <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                    from Revision
                  </button>
                </div>
                <div class="flex flex-column content-cntr">
                  <button class="modal-close" @click="closeModal()">
                    <span class="material-icons" style="color: #ffffff !important">close</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="showSubjective" class="subjective-section flex content-btw">
              <div :style="showRevisionModal ? 'filter: blur(2px) !important' : ''" class="flex">
                <button class="concept-button fw-600 fs-13 ln-18" style="" @click="showConcept()">
                  VIEW CONCEPT
                </button>
              </div>
              <div :style="showRevisionModal ? 'filter: blur(2px) !important' : ''" class="flex">
                <button class="next-button fw-600 fs-13 ln-18" @click="previousQuestion()">
                  Previous
                </button>
                <button class="next-button fw-600 fs-13 ln-18" @click="nextQuestion()">Next</button>
                <button
                  class="option-button-1 center-align"
                  @click="showOther()"
                  style="background: #693383"
                >
                  <img class="option-img" :src="require(`../assets/threedots.svg`)" />
                </button>
              </div>
            </div>
            <div v-if="showRevisionModal" class="revision-modal-1 flex content-even">
              <div class="flex flex-column content-even">
                <button
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
            <div v-if="showMultiOption" class="answer-objective flex content-btw">
              <div
                class="flex content-btw"
                style="width: 745px"
                :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
              >
                <div class="flex">
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedMultiOption.includes('A') }"
                    value="A"
                    @click="checkMultiOption($event)"
                  >
                    A
                  </button>
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedMultiOption.includes('B') }"
                    value="B"
                    @click="checkMultiOption($event)"
                  >
                    B
                  </button>
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedMultiOption.includes('C') }"
                    value="C"
                    @click="checkMultiOption($event)"
                  >
                    C
                  </button>
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedMultiOption.includes('D') }"
                    value="D"
                    @click="checkMultiOption($event)"
                  >
                    D
                  </button>
                </div>
                <div
                  class="flex content-cntr"
                  :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
                >
                  <hr class="answer-section-hr" style="margin-top: 24px" />
                  <button
                    class="submit-button fw-600 fs-13 ln-18"
                    :class="{ active: selectedMultiOption !== '' }"
                    style="margin-top: 8px"
                    @click="submitMultiAnswer()"
                  >
                    SUBMIT
                  </button>
                  <button
                    class="skip-button fw-600 fs-13 ln-18"
                    style="margin-top: 8px"
                    @click="skipQuestion()"
                  >
                    SKIP
                  </button>
                </div>
              </div>
              <div style="margin-top: 3px !important">
                <button
                  class="option-button center-align"
                  @click="showOther()"
                  style="background: #693383"
                >
                  <img class="option-img" :src="require(`../assets/threedots.svg`)" />
                </button>
              </div>
              <div
                v-if="showRevisionModal"
                class="revision-modal flex content-even"
                style="top: 0 !important; right: 0 !important"
              >
                <div class="flex flex-column content-even">
                  <button
                    class="flex content-left revision-button fs-13 fw-600"
                    @click="removeRevision()"
                  >
                    <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                    from Revision
                  </button>
                </div>
                <div class="flex flex-column content-cntr">
                  <button class="modal-close" @click="closeModal()">
                    <span class="material-icons" style="color: #ffffff !important">close</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="showNumeric" class="answer-objective flex content-btw">
              <div
                class="flex content-btw"
                style="width: 745px"
                :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
              >
                <div class="flex number-input" style="max-width: 320px">
                  <input
                    class="numeric-field"
                    type="number"
                    placeholder="Enter Number"
                    inputmode="numeric"
                    v-model="numericAnswer"
                  />
                </div>
                <div
                  class="flex content-cntr"
                  :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
                >
                  <hr class="answer-section-hr" style="margin-top: 24px" />
                  <button
                    class="submit-button fw-600 fs-13 ln-18"
                    :class="{ active: numericAnswer !== null }"
                    style="margin-top: 8px"
                    @click="submitNumericAnswer()"
                  >
                    SUBMIT
                  </button>
                  <button
                    class="skip-button fw-600 fs-13 ln-18"
                    style="margin-top: 8px"
                    @click="skipQuestion()"
                  >
                    SKIP
                  </button>
                </div>
              </div>
              <div style="margin-top: 3px !important">
                <button
                  class="option-button center-align"
                  @click="showOther()"
                  style="background: #693383"
                >
                  <img class="option-img" :src="require(`../assets/threedots.svg`)" />
                </button>
              </div>
              <div
                v-if="showRevisionModal"
                class="revision-modal flex content-even"
                style="top: 0 !important; right: 0 !important"
              >
                <div class="flex flex-column content-even">
                  <button
                    class="flex content-left revision-button fs-13 fw-600"
                    @click="removeRevision()"
                  >
                    <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                    from Revision
                  </button>
                </div>
                <div class="flex flex-column content-cntr">
                  <button class="modal-close" @click="closeModal()">
                    <span class="material-icons" style="color: #ffffff !important">close</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="showTrueFalse" class="answer-objective flex content-btw">
              <div
                class="flex content-btw"
                style="width: 745px"
                :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
              >
                <div class="flex number-input" style="max-width: 320px">
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedOption === 'A', deactive: answerGiven === 'A' }"
                    value="A"
                    @click="checkAnswer($event)"
                  >
                    True
                  </button>
                  <button
                    class="answer-button fw-600 fs-20"
                    :class="{ active: selectedOption === 'B', deactive: answerGiven === 'B' }"
                    value="B"
                    @click="checkAnswer($event)"
                  >
                    False
                  </button>
                </div>
                <div
                  class="flex content-cntr"
                  :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
                >
                  <hr class="answer-section-hr" style="margin-top: 24px" />
                  <button
                    class="submit-button fw-600 fs-13 ln-18"
                    :class="{ active: numericAnswer !== null }"
                    style="margin-top: 8px"
                    @click="submitNumericAnswer()"
                  >
                    SUBMIT
                  </button>
                  <button
                    class="skip-button fw-600 fs-13 ln-18"
                    style="margin-top: 8px"
                    @click="skipQuestion()"
                  >
                    SKIP
                  </button>
                </div>
              </div>
              <div style="margin-top: 3px !important">
                <button
                  class="option-button center-align"
                  @click="showOther()"
                  style="background: #693383"
                >
                  <img class="option-img" :src="require(`../assets/threedots.svg`)" />
                </button>
              </div>
              <div
                v-if="showRevisionModal"
                class="revision-modal flex content-even"
                style="top: 0 !important; right: 0 !important"
              >
                <div class="flex flex-column content-even">
                  <button
                    class="flex content-left revision-button fs-13 fw-600"
                    @click="removeRevision()"
                  >
                    <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                    from Revision
                  </button>
                </div>
                <div class="flex flex-column content-cntr">
                  <button class="modal-close" @click="closeModal()">
                    <span class="material-icons" style="color: #ffffff !important">close</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="subjective-section flex content-btw">
              <div :style="showRevisionModal ? 'filter: blur(2px) !important' : ''" class="flex">
                <button class="concept-button fw-600 fs-13 ln-18" style="" @click="showConcept()">
                  VIEW CONCEPT
                </button>
              </div>
              <div :style="showRevisionModal ? 'filter: blur(2px) !important' : ''" class="flex">
                <button class="next-button fw-600 fs-13 ln-18" @click="prevQuestion()">
                  Previous
                </button>
                <button class="next-button fw-600 fs-13 ln-18" @click="nextQuestion()">Next</button>
                <button
                  class="option-button-1 center-align"
                  @click="showOther()"
                  style="background: #693383"
                >
                  <img class="option-img" :src="require(`../assets/threedots.svg`)" />
                </button>
              </div>
            </div>
            <div v-if="showRevisionModal" class="revision-modal-1 flex content-even">
              <div class="flex flex-column content-even">
                <button
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeClarification()"
                >
                  <img
                    class="revision-img-1"
                    :src="require(`../assets/problem-solution.svg`)"
                  />Remove for Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="conceptDiv === true" style="margin-top: 10px">
        <div class="flex content-btw earn-head" style="background: none !important">
          <div class="flex content-btw" style="">
            <span
              class="fw-400 fs-18 concept-head-button"
              :class="{ selected: selectedConcept === index }"
              v-for="(data, index) in kscData"
              :key="index"
              @click="viewConcept(data, index)"
            >
              Concept {{ index + 1 }}</span
            >
            <span
              style="cursor: pointer"
              :class="{ selected: selectedTab === 2 }"
              class="fw-400 fs-18 concept-head-button"
              @click="showFullSolution()"
              >Solution</span
            >
            <span
              style="cursor: pointer"
              v-if="fullSolutionVideoUrl !== null"
              :class="{ selected: selectedTab === 3 }"
              class="fw-400 fs-18 concept-head-button"
              @click="showVideo()"
              >Video</span
            >
          </div>
          <div @click="conceptDiv = false" style="cursor: pointer">
            <span class="material-icons earn-close" style="color: #ffffff !important">close</span>
          </div>
        </div>
        <div style="height: 395px !important">
          <div v-if="selectedConcept !== null" style="margin-top: 10px; margin-left: 15px">
            <img :src="kscDiagram" class="concept-image-2" alt="Concept Image" draggable="false" />
          </div>
          <div v-if="selectedTab == 2" style="margin-top: 10px; margin-left: 15px">
            <img
              :src="fullSolutionImageUrl"
              class="full-solution-image-2"
              alt="Solution Image"
              draggable="false"
            />
          </div>
          <div v-if="selectedTab == 3" style="margin-top: 10px; margin-left: 15px">
            <iframe :src="fullSolutionVideoUrl" style="width: max-content"></iframe>
          </div>
        </div>
        <div class="answer-section-1">
          <div v-if="correctAnswer" class="correct-section flex content-btw relative">
            <div class="flex" :style="showRevisionModal ? 'filter: blur(2px) !important' : ''">
              <img
                :src="require(`../assets/correct-answer.svg`)"
                class="center-align correct-image"
              />
              <div class="flex column">
                <span class="fw-700 fs-15 blue-text ln-20">Correct Answer !</span>
              </div>
            </div>
            <div
              class="flex relative"
              :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
            >
              <hr class="answer-section-hr" style="margin-top: 24px" />
              <button
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="showConcept()"
              >
                VIEW SOLUTION
              </button>
              <button
                class="next-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="nextQuestion()"
              >
                NEXT
              </button>
              <button
                class="option-button center-align"
                @click="showOther()"
                style="margin-top: 3px !important; background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div v-if="showRevisionModal" class="revision-modal flex content-even">
              <div class="flex flex-column content-even">
                <button
                  v-if="isTutorHelp === 0"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button v-else class="flex content-left revision-button-1 fs-13 fw-600">
                  <img
                    class="revision-img-1"
                    :src="require(`../assets/problem-solution.svg`)"
                  />Remove for Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="wrongAnswer" class="correct-section flex content-btw relative">
            <div class="flex" :style="showRevisionModal ? 'filter: blur(2px) !important' : ''">
              <img
                :src="require(`../assets/wrong-answer.svg`)"
                class="center-align correct-image"
              />
              <div class="flex column">
                <span class="fw-700 fs-15 red-text ln-20">Wrong Answer !</span>
                <span class="fw-400 fs-13 blk-text ln-18 wrong-text"
                  >Please review concepts and try again.</span
                >
              </div>
            </div>
            <div
              class="flex relative"
              :style="showRevisionModal ? 'filter: blur(2px) !important' : ''"
            >
              <hr class="answer-section-hr" style="margin-top: 24px" />
              <button
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="showConcept()"
              >
                VIEW CONCEPT
              </button>
              <!-- <button class="concept-button fw-600 fs-13 ln-18" style="margin-top: 8px;" @click="showSolution()">VIEW SOLUTION</button> -->
              <button
                class="concept-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="tryAgainQuestion()"
              >
                TRY AGAIN
              </button>
              <button
                class="next-button fw-600 fs-13 ln-18"
                style="margin-top: 8px"
                @click="nextQuestion()"
              >
                NEXT
              </button>
              <button
                class="option-button center-align"
                @click="showOther()"
                style="margin-top: 3px !important; background: #693383"
              >
                <img class="option-img" :src="require(`../assets/threedots.svg`)" />
              </button>
            </div>
            <div v-if="showRevisionModal" class="revision-modal flex content-even">
              <div class="flex flex-column content-even">
                <button
                  v-if="isTutorHelp === 0"
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeRevision()"
                >
                  <img class="revision-img" :src="require(`../assets/revise-load.svg`)" />Remove
                  from Revision
                </button>
                <button
                  v-else
                  class="flex content-left revision-button fs-13 fw-600"
                  @click="removeClarification()"
                >
                  <img
                    class="revision-img-1"
                    :src="require(`../assets/problem-solution.svg`)"
                  />Remove for Clarification
                </button>
              </div>
              <div class="flex flex-column content-cntr">
                <button class="modal-close" @click="closeModal()">
                  <span class="material-icons" style="color: #ffffff !important">close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="portrait">
      <div class="valign-wrapper">
        <div class="valign">
          <div style="margin-bottom: 100px">
            <div class="rotate"></div>
            <h5 class="light">Please rotate your device</h5>
            <span
              >We don't support portrat mode yet. Rotate your device to landscape mode for the best
              experience</span
            >
          </div>
        </div>
      </div>
    </div>
    <Modal3
      :show="conceptModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="conceptModal = false"
    >
      <template v-slot:body>
        <div class="concept-modal">
          <div class="flex content-btw earn-head">
            <div class="flex content-btw" style="">
              <span
                class="fw-400 fs-18 concept-head-button"
                :class="{ selected: selectedConcept === index }"
                v-for="(data, index) in kscData"
                :key="index"
                @click="viewConcept(data, index)"
              >
                Concept {{ index + 1 }}</span
              >
              <span
                style="cursor: pointer"
                :class="{ selected: selectedTab === 2 }"
                class="fw-400 fs-18 concept-head-button"
                @click="showFullSolution()"
                >Solution</span
              >
              <span
                style="cursor: pointer"
                v-if="fullSolutionVideoUrl !== null"
                :class="{ selected: selectedTab === 3 }"
                class="fw-400 fs-18 concept-head-button"
                @click="showVideo()"
                >Video</span
              >
            </div>
            <div @click="conceptModal = false" style="cursor: pointer">
              <span class="material-icons earn-close" style="color: #ffffff !important">close</span>
            </div>
          </div>
          <div v-if="selectedConcept !== null">
            <img :src="kscDiagram" class="concept-image" alt="Concept Image" draggable="false" />
          </div>
          <div v-if="selectedTab == 2">
            <img
              :src="fullSolutionImageUrl"
              class="full-solution-image"
              alt="Solution Image"
              draggable="false"
            />
          </div>
          <div v-if="selectedTab == 3">
            <iframe :src="fullSolutionVideoUrl" style="width: 100%; height: 500px"></iframe>
          </div>
        </div>
      </template>
    </Modal3>
    <Modal2
      :show="reportModal"
      :showHeader="false"
      :showCloseBtn="false"
      @close="reportModal = false"
    >
      <template v-slot:body>
        <div class="report-modal">
          <div class="flex content-cntr" style="margin-bottom: 10px; padding-top: 20px">
            <img class="" :src="require(`../assets/errorsvg.svg`)" />
          </div>
          <span class="flex content-cntr fs-20 fw-700 ln-27" style="margin-bottom: 8px"
            >Report Error</span
          >
          <hr class="report-line" style="border: 1px solid #e0e4f0" />
          <div style="margin-top: 20px; margin-left: 23px">
            <span class="fs-20 fw-600 ln-17">What went wrong with this question?</span>
            <div class="flex flex-column">
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="1"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">KSC not helpful</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="2"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Answer tagging wrong</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="3"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Mistake in Q and A</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="5"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Unclear image</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="6"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Teacher feedback</span></label
              >
              <label class="report-label"
                ><input
                  type="radio"
                  class="with-gap"
                  id="ksc"
                  value="4"
                  v-model="reportErrorValue"
                /><span class="fw-400 fs-15 blk-text" for="ksc">Other errors</span></label
              >
            </div>
            <div class="flex content-btw" style="width: 264px !important; margin-top: 30px">
              <button class="end-cancel-button fs-13 fw-600 ln-18" @click="cancelEndModal()">
                CANCEL
              </button>
              <button class="end-confirm-button fs-13 fw-600 ln-18" @click="submitErrorReport()">
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </template>
    </Modal2>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import MobileAPI from "../Mobileapi";
import Modal2 from "../components/Model2.vue";
import Modal3 from "../components/Modal3.vue";

const toast = useToast();

export default {
  data() {
    return {
      selectedOption: "",
      correctAnswer: false,
      wrongAnswer: false,
      showOptions: false,
      showRevisionModal: false,
      testTypeId: null,
      questionImage: "",
      questionAnswer: "",
      questionId: null,
      userTestSessionQuestionId: null,
      questionData: [],
      kscData: [],
      conceptModal: false,
      conceptDiv: false,
      selectedTab: 1,
      kscDiagram: "",
      selectedConcept: null,
      questionScore: null,
      showRevision: false,
      showClarification: false,
      endModal: false,
      showSubjective: false,
      showNumeric: false,
      showMultiOption: false,
      showAssertion: false,
      showTrueFalse: false,
      selectedMultiOption: "",
      numericAnswer: null,
      reportModal: false,
      reportErrorValue: "",
      answerGiven: "",
      answerNumeric: null,
      appModal: false,
      windowWidth: window.innerWidth,
      // new code
      currentQuestionId: null,
      isAnsCorrect: false,
      currentQnIndex: 0,
      isMarkForRevision: false,
      isMarkForClarification: false,
      markForRevisionQuestionId: null,
      isMobileDevice: false,
      questionLatexStatus: "",
      questionLatex: "",
      questionDiagram: "",
      questionPosition: "",
      questionLatexDiagram: "",
      questionOptionA: "",
      questionOptionB: "",
      questionOptionC: "",
      questionOptionD: "",
      selectedQuestionData: [],
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        showProcessingMessages: false,
        jax: ["input/TeX", "output/chtml"],
        lazyAlwaysTypeset: null,
        showMathMenu: false,
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: false,
          processClass: "tex2jax_process",
        },
      },
      isTutorHelp: null,
      fullSolutionVideoUrl: "",
      fullSolutionImageUrl: "",
      examSessionQuestionId: null,
      revisionQuestionId: null,
      examRevisionQuestionId: null,
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  unmounted() {},
  activated() {
    if (localStorage && localStorage.getItem("SLToken")) {
      this.slToken = localStorage.getItem("SLToken") || "";
    }
    this.currentQnIndex = 0;
    this.questionData = JSON.parse(this.$route.params.questionList);
    this.isTutorHelp = Number(this.$route.params.isTutorHelp);
    this.currentQnIndex = Number(this.$route.params.index);
    this.getQuestion(this.questionData[this.currentQnIndex]);
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    markTextHandler() {
      if (this.isMarkForClarification) {
        return "Marked";
      }
      return "Mark";
    },
  },
  methods: {
    getAzureUrl(imageUrl) {
      if (imageUrl.startsWith("~")) {
        imageUrl = imageUrl.replace("~", process.env.VUE_APP_STORAGE_URL);
      }
      return imageUrl;
    },
    close() {
      this.$router.go(-1);
    },
    skipQuestion() {
      this.currentQnIndex += 1;
      this.selectedOption = "";
      this.selectedMultiOption = "";
      this.answerGiven = "";
      this.numericAnswer = null;
      if (this.currentQnIndex >= this.questionData.length) {
        toast.error("No More Question Left");
      } else {
        this.getQuestion(this.questionData[this.currentQnIndex]);
      }
    },
    nextQuestion() {
      this.currentQnIndex += 1;
      this.conceptModal = false;
      this.conceptDiv = false;
      this.correctAnswer = false;
      this.wrongAnswer = false;
      this.selectedOption = "";
      this.selectedMultiOption = "";
      this.answerGiven = "";
      this.numericAnswer = null;
      if (this.currentQnIndex >= this.questionData.length) {
        toast.error("No More Question Left");
      } else {
        this.getQuestion(this.questionData[this.currentQnIndex]);
      }
    },
    prevQuestion() {
      this.currentQnIndex -= 1;
      this.conceptModal = false;
      this.conceptDiv = false;
      if (this.currentQnIndex === 0) {
        toast.error("No More Question Left");
      } else {
        this.getQuestion(this.questionData[this.currentQnIndex]);
      }
    },
    tryAgainQuestion() {
      if (this.testTypeId === 1) {
        this.showSubjective = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showOptions = true;
        this.showTrueFalse = false;
      } else if (this.testTypeId === 2) {
        this.showOptions = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showTrueFalse = false;
        this.showSubjective = true;
      } else if (this.testTypeId === 3) {
        this.showSubjective = false;
        this.showTrueFalse = false;
        this.showOptions = false;
        this.showNumeric = false;
        this.showMultiOption = true;
      } else if (this.testTypeId === 4) {
        this.showMultiOption = false;
        this.showSubjective = false;
        this.showTrueFalse = false;
        this.showOptions = false;
        this.showNumeric = true;
      } else if (this.testTypeId === 5) {
        this.showSubjective = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showTrueFalse = false;
        this.showOptions = true;
      } else if (this.testTypeId === 8) {
        this.showTrueFalse = true;
        this.showSubjective = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showOptions = false;
      }
      this.correctAnswer = false;
      this.wrongAnswer = false;
    },
    getQuestion(ques) {
      const user = this.$store.getters.user;
      const data = {
        QuestionId: ques.QuestionId,
        UserId: user.UserId,
      };
      if (ques.ExamSessionQuestionId !== undefined && ques.ExamSessionQuestionId !== null) {
        this.examSessionQuestionId = ques.ExamSessionQuestionId;
        this.userTestSessionQuestionId = ques.UserTestSessionQuestionId;
      }
      if (ques.RevisionQuestionId !== undefined && ques.RevisionQuestionId !== null) {
        this.userTestSessionQuestionId = ques.UserTestSessionQuestionId;
        this.examRevisionQuestionId = ques.ExamRevisionQuestionId;
      }
      MobileAPI.getEntireDataOfQuestion(data, (response) => {
        if (response.data !== null && Object.values(response.data).length > 0) {
          this.selectedQuestionData = response.data;
          this.testTypeId = this.selectedQuestionData.QuestionTypeId;
          this.getQuestionData(this.selectedQuestionData);
        }
      });
    },
    getQuestionData(ques) {
      if (this.testTypeId === 1) {
        this.showSubjective = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showOptions = true;
      } else if (this.testTypeId === 2) {
        this.showOptions = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showSubjective = true;
      } else if (this.testTypeId === 3) {
        this.showSubjective = false;
        this.showOptions = false;
        this.showNumeric = false;
        this.showMultiOption = true;
      } else if (this.testTypeId === 4) {
        this.showMultiOption = false;
        this.showSubjective = false;
        this.showOptions = false;
        this.showNumeric = true;
      } else if (this.testTypeId === 5) {
        this.showSubjective = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.showOptions = true;
      }
      this.questionImage = ques.QuestionDiagramURL;
      this.questionAnswer = ques.AnswerOption;
      this.questionId = ques.QuestionId;
      this.questionScore = ques.QuestionScore;
      this.questionLatexStatus = ques.LatexStatus;
      this.questionLatex = ques.Q_LATEX;
      this.questionLatexDiagram = JSON.parse(ques.Diagram);
      if (this.questionLatexDiagram !== null) {
        this.questionPosition = this.questionLatexDiagram.diagram_0.position;
        this.questionDiagram = this.questionLatexDiagram.diagram_0.url;
      }
      this.questionOptionA = ques.AnswerOptionA;
      this.questionOptionB = ques.AnswerOptionB;
      this.questionOptionC = ques.AnswerOptionC;
      this.questionOptionD = ques.AnswerOptionD;
      this.kscData = ques.KscDiagrams;
      this.kscDiagram = this.kscData[0];
      this.fullSolutionImageUrl = ques.FullSolutionURL;
      this.fullSolutionVideoUrl = ques.FullSolutionVideoUrl;
      //   this.userTestSessionQuestionId = ques.data.userTestSessionQuestionId;
    },
    validateNumber: (event) => {
      const keyCode = event.keyCode;
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
        event.preventDefault();
      }
    },
    checkAnswer(event) {
      this.selectedOption = event.target.value;
    },
    checkMultiOption(event) {
      if (this.selectedMultiOption.includes(event.target.value) === true) {
        this.selectedMultiOption = this.selectedMultiOption.replace(event.target.value, "");
      } else if (this.selectedMultiOption.includes(event.target.value) === false) {
        this.selectedMultiOption = this.selectedMultiOption.concat(event.target.value);
      }
    },
    submitErrorReport() {
      const data = {
        userTestSessionQuestionId: this.userTestSessionQuestionId
          ? this.userTestSessionQuestionId
          : 0,
        tag: parseInt(this.reportErrorValue),
        examSessionQuestionId: this.examSessionQuestionId ? this.examSessionQuestionId : 0,
      };
      MobileAPI.reportErrorQuestion(data, (response) => {
        if (response.status === 200) {
          this.showErrorReport = false;
          this.errorReportModal = true;
        }
      });
      this.reportErrorValue = "";
      this.reportModal = false;
    },
    submitAnswer() {
      this.isAnsCorrect = false;
      this.promptVisible = false;
      if (this.selectedOption === this.questionAnswer) {
        this.correctAnswer = true;
        this.showOptions = false;
        this.isAnsCorrect = true;
        this.answerGiven = this.selectedOption;
        this.selectedOption = "";
      } else {
        this.wrongAnswer = true;
        this.correctAnswer = false;
        this.showOptions = false;
        // new code s
        this.isAnsCorrect = false;
        this.answerGiven = this.selectedOption;
        this.selectedOption = "";
      }
    },
    sortAlphabets(text) {
      this.selectedMultiOption = text.split("").sort().join("");
    },
    submitMultiAnswer() {
      this.isAnsCorrect = false;
      this.promptVisible = false;
      this.showMultiOption = false;
      this.sortAlphabets(this.selectedMultiOption);
      if (this.selectedMultiOption === this.questionAnswer) {
        this.correctAnswer = true;
        this.isAnsCorrect = true;
        this.showMultiOption = false;
      } else {
        this.wrongAnswer = true;
        this.correctAnswer = false;
        this.isAnsCorrect = false;
        this.showMultiOption = false;
        this.selectedMultiOption = "";
      }
    },
    submitNumericAnswer() {
      this.promptVisible = false;
      this.isAnsCorrect = false;
      if (this.numericAnswer === Number(this.questionAnswer)) {
        this.correctAnswer = true;
        this.isAnsCorrect = true;
        this.showNumeric = false;
        this.numericAnswer = null;
      } else {
        this.wrongAnswer = true;
        this.correctAnswer = false;
        this.isAnsCorrect = false;
        this.showMultiOption = false;
        this.showNumeric = false;
        this.answerNumeric = this.numericAnswer;
        // this.numericAnswer = null;
      }
    },
    showConcept() {
      if (this.isMobileDevice === true) {
        this.conceptDiv = true;
      } else if (this.isMobileDevice === false) {
        this.conceptModal = true;
      }
      this.selectedConcept = 0;
      this.kscDiagram = "";
      this.selectedTab = null;
      this.kscDiagram = this.kscData[0];
    },
    showKsc() {
      this.selectedTab = 1;
    },
    showFullSolution() {
      if (this.isMobileDevice === true) {
        this.conceptDiv = true;
      } else if (this.isMobileDevice === false) {
        this.conceptModal = true;
      }
      this.selectedConcept = null;
      this.selectedTab = 2;
    },
    showVideo() {
      this.selectedTab = 3;
    },
    viewConcept(data, index) {
      if (this.isMobileDevice === true) {
        this.conceptDiv = true;
      } else if (this.isMobileDevice === false) {
        this.conceptModal = true;
      }
      this.kscDiagram = "";
      this.selectedConcept = index;
      this.kscDiagram = data;
      this.selectedTab = null;
    },
    showOther() {
      this.showRevisionModal = true;
    },
    closeModal() {
      this.showRevisionModal = false;
      this.showRevision = false;
      this.showClarification = false;
    },
    removeRevision() {
      if (this.examRevisionQuestionId !== 0 && this.examRevisionQuestionId !== undefined) {
        const data = {
          ExamRevisionQuestionId: this.examRevisionQuestionId,
        };
        MobileAPI.removeExamRevisionQuestion(data, (response) => {
          const removedQuestion = response.data;
          toast.success("Removed SuccessFully");
          console.log(removedQuestion);
        });
      } else {
        const data = {
          usertestSessionQuestionId: this.userTestSessionQuestionId,
          revisionQuestionId: 0,
        };
        MobileAPI.RemoveRevision(data, (response) => {
          if (response.responseCode === 200) {
            toast.success("Removed SuccessFully");
            this.showRevision = false;
            this.markForRevisionQuestionId = null;
            this.MarkForRevision = true;
            this.RemoveFromRevision = false;
          }
        });
      }
    },
    removeClarification() {
      let data = {};
      if (this.userTestSessionQuestionId !== 0) {
        data = {
          UserTestSessionQuestionId: this.userTestSessionQuestionId,
        };
      } else {
        data = {
          ExamSessionQuestionId: this.examSessionQuestionId,
        };
      }
      MobileAPI.assignmentMarkForClarification(data, (response) => {
        this.markForClarification = response.data;
        toast.success("Removed SuccessFully");
        this.showClarification = false;
        this.isMarkForClarification = false;
        // this.markQuestionForClarification(this.isMarkForClarification);
      });
    },
    cancelEndModal() {
      this.endModal = false;
      this.reportModal = false;
      this.noEasyQuestion = false;
      this.noHardQuestion = false;
      this.noMoreNewQuestion = false;
    },
    showSolution() {
      if (this.isMobileDevice === true) {
        this.conceptDiv = true;
      } else if (this.isMobileDevice === false) {
        this.conceptModal = true;
      }
      this.selectedTab = 2;
      this.selectedConcept = null;
    },
  },
  components: {
    Modal2,
    Modal3,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

.practice-session {
  font-family: "Open Sans" !important;
}

.blk-text {
  color: #333333;
}

.blue-text {
  color: #16bcb2 !important;
}

.red-text {
  color: #ff7675 !important;
}

.blue-bg {
  background-color: #3751ff !important;
}

.white-bg {
  background-color: #fff !important;
}

.white-font {
  color: #fff !important;
}

.ln-18 {
  line-height: 18px !important;
}

.ln-20 {
  line-height: 20px !important;
}

.ln-16 {
  line-height: 16px !important;
}

.ln-17 {
  line-height: 17px !important;
}

.ln-27 {
  line-height: 27.24px !important;
}

.flex {
  display: flex !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.content-btw {
  justify-content: space-between !important;
}

.content-cntr {
  justify-content: center !important;
}

.content-even {
  justify-content: space-evenly !important;
}

.content-around {
  justify-content: space-around !important;
}

.content-left {
  justify-content: left !important;
}

.column {
  flex-direction: column !important;
}

.flex-center {
  justify-content: center;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.active {
  background: #37841c !important;
  color: #ffffff !important;
  cursor: pointer !important;
  pointer-events: all !important;
}

.deactive {
  background: grey !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.question-image {
  max-height: 380px !important;
  max-width: 900px !important;
  min-width: 600px !important;
  border: 1px solid #f5f5f9;
}

.session-box {
  box-sizing: border-box;
  max-width: 1174px;
  min-width: 300px;
  height: 615px;
  border: 1px solid #e0e4f0;
  border-radius: 6px 6px 0px 0px;
  margin-top: 20px;
  background: #ffffff !important;
  position: relative;
}

.header-box {
  box-sizing: border-box;
  position: relative;
  max-width: 1175px;
  min-width: 300px;
  height: 70px;
  border: 1px solid #e0e4f0;
  border-radius: 6px 6px 0px 0px;
}

.header-options {
  height: 59px;
  margin: 5px 20px 10px 22px;
  opacity: 1 !important;
}

.header-right {
  display: flex !important;
}

.header-left {
  margin-top: 13px;
}

.header-right-button {
  width: 20px;
  height: 55px;
  background: #693383;
  border-radius: 4px;
  border: none;
  margin: 3px 10px 5px 0px;
}

.button-size {
  width: 40px !important;
  height: 34px !important;
  cursor: pointer;
}
.diagramURL {
  overflow: hidden !important;
  height: auto;
  max-width: 100%;
  flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
}

.answer-section {
  box-sizing: border-box;
  position: relative;
  bottom: -225px;
  max-width: 1134px;
  min-width: 300px;
  height: 82px;
  margin: 0px 20px 20px 20px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 0px 0px 25px rgba(139, 151, 186, 0.2) !important;
  border-radius: 6px;
  z-index: 100;
}
.answer-section-latex {
  box-sizing: border-box;
  position: relative;
  bottom: -38px;
  max-width: 1134px;
  min-width: 300px;
  height: 82px;
  margin: 0px 20px 20px 20px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 0px 0px 25px rgba(139, 151, 186, 0.2) !important;
  border-radius: 6px;
  z-index: 100;
}
.latex-section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.023);
  background-color: #f9f9f9;
  border-radius: 5px;
}

.latex-section::-webkit-scrollbar {
  width: 6px;
  background-color: #f9f9f9;
}

.latex-section::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #999fb7;
}
.selection-btn {
  box-sizing: border-box;
  min-height: 41.22px;
  min-width: 41.22px;
  border: 1px solid rgba(21, 15, 45, 0.12);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #fff;
  margin-right: 10px;
}
.answer-section-1 {
  box-sizing: border-box;
  position: relative;
  bottom: -12px;
  max-width: 1134px;
  min-width: 300px;
  height: 82px;
  margin: 0px 20px 20px 20px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  box-shadow: 0px 0px 25px rgba(139, 151, 186, 0.2) !important;
  border-radius: 6px;
}

.answer-objective {
  margin: 16px 20px 8px 20px;
  height: 51px !important;
  max-width: 1090px !important;
}

.answer-button {
  width: 60px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
}

.answer-button:hover {
  width: 60px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  color: #37841c;
}

.answer-section-hr {
  width: 34px;
  border: 1px solid #dedcdc;
  transform: rotate(90deg);
}

.submit-button {
  width: 99px;
  height: 34px;
  background: #fbfbfb;
  border-radius: 4px;
  border: none;
  color: #e0e4f0;
  margin-right: 20px;
  cursor: not-allowed;
  pointer-events: none;
}

.skip-button {
  width: 78px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  color: #37841c;
  cursor: pointer;
}

.skip-button:hover {
  color: #ffffff;
  background: #37841c;
}

.option-button {
  width: 17px;
  height: 42px;
  border-radius: 4px;
  border: none;
  /* margin: 24px 20px 24px 0px; */
  cursor: pointer;
}

.option-button-1 {
  width: 17px;
  height: 42px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-top: -4px;
}

.option-img {
  width: 4px;
  height: 19px;
  display: flex;
  justify-content: center;
}

.correct-image {
  width: 66px;
  height: 50px;
  margin-right: 15px;
}

.concept-button {
  width: 145px;
  height: 34px;
  background: #f3f5fc;
  border-radius: 4px;
  border: none;
  color: #3751ff;
  margin-right: 20px;
  cursor: pointer;
}

.concept-button:hover {
  background: #3751ff;
  color: #ffffff;
}

.concept-button-1 {
  width: 145px;
  height: 34px;
  background: #fbfbfb;
  border-radius: 4px;
  border: none;
  color: #e0e4f0;
  margin-right: 20px;
  cursor: not-allowed;
}

.concept-image-2 {
  max-height: 400px !important;
  max-width: 900px !important;
  min-width: 600px !important;
  border: 1px solid #f5f5f9;
}

.next-button {
  width: 83px;
  height: 34px;
  background: #ffffff;
  /* Action 1 */
  border: 1px solid #37841c;
  border-radius: 4px;
  margin-right: 20px;
  color: #37841c;
  cursor: pointer;
}

.next-button:hover {
  background: #37841c;
  color: #ffffff;
}

.correct-section {
  height: 51px !important;
  max-width: 1090px !important;
  margin: 16px 20px 8px 20px !important;
}

.revision-modal {
  position: absolute;
  width: 304px;
  height: 80px;
  right: -20px;
  top: -16px;

  background: #ffffff;
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid #e0e4f0;
}

.revision-modal-1 {
  position: absolute;
  width: 304px;
  height: 80px;
  right: 0px;
  top: 0px;

  background: #ffffff;
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid #e0e4f0;
}

.modal-close {
  width: 34px;
  height: 34px;
  background: #ff7675;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.revision-button {
  width: 194px;
  height: 28px;
  background: #f3f5fc;
  border-radius: 4px;
  color: #3751ff;
  border: none;
  padding: 5px;
  cursor: pointer;
  /* display: flex; */
}

.revision-button-1 {
  width: 194px;
  height: 28px;
  background: #3751ff;
  border-radius: 4px;
  color: #f3f5fc;
  border: none;
  padding: 5px;
  cursor: pointer;
  /* display: flex; */
}

.revision-img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.revision-img-1 {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.image-section {
  max-width: 880px;
  min-width: 300px;
  height: 185px;
  min-height: 85px;
  padding: 11px;
}

.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 15px;
  border-radius: 5px 5px 0 0;
}

.earn-close {
  background-color: #ff7675 !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.concept-modal {
  max-width: 1173px;
}

.selected {
  color: #3751ff;
  font-weight: 700 !important;
  font-size: 20px;
  line-height: 27px;
  border-bottom: 2px #3751ff;
}

.concept-modal-button {
  width: 115px;
  height: 30px;
  background: #f8f8f8;
  border-radius: 4px;
  border: none;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  margin-right: 20px;
  opacity: 0.65;
}

.end-modal {
  width: 450px;
  height: 356px;
  background: #ffffff;
  border-radius: 10px;
}

.no-ques-modal {
  width: 520px;
  height: 380px;
  background: #ffffff;
  border-radius: 10px;
}
.latex-section {
  height: 380px !important;
  /* max-width: 900px !important; */
  min-width: 600px !important;
  border: 1px solid #f5f5f9;
  overflow-y: scroll;
  padding: 10px;
}

.report-modal {
  width: 450px;
  height: 560px !important;
  background: #ffffff;
  border-radius: 10px !important;
}

.end-cancel-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #37841c;
  border-radius: 4px;
  background: #ffffff;
  color: #37841c;
  cursor: pointer;
}

.end-cancel-button:hover {
  background: #37841c !important;
  color: #ffffff !important;
}

.end-confirm-button {
  width: 114px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #ff7675;
  border-radius: 4px;
  color: #ff7675;
  cursor: pointer;
}

.end-confirm-button:hover {
  background: #ff7675 !important;
  color: #ffffff !important;
}

.subjective-section {
  height: 34px;
  margin: 24px 24px 24px 20px;
  max-width: 1090px !important;
}

.progress1 {
  position: relative;
  max-width: 100%;
  height: 6px;
  background: #f2f3f8;
  /* margin-bottom: 15px; */
}

.progress-bar1 {
  position: absolute;
  height: 100%;
  border-radius: 30px;
}

.question-type-button {
  /* width: 121px; */
  height: 21px;
  background: #f6e2ff;
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #693383;
  margin-right: 9px;
  padding-left: 10px;
  padding-right: 10px;
}

.image-button {
  border: 1px solid #e0e4f0;
  border-radius: 4px;
  width: 117px;
  height: 34px;
  background: #ffffff;
  color: #3751ff;
  padding: 7px 10px;
  margin-right: 12px;
}

.numeric-field {
  background: #ffffff;
  width: 380px !important;
  height: 51px !important;
  border: 1px solid #e0e4f0 !important;
  border-radius: 4px !important;
}

.number-input input {
  padding-left: 10px !important;
}

.full-solution-image-2 {
  max-width: 900px !important;
  min-width: 600px !important;
  max-height: 400px;
  border: 1px solid #f5f5f9;
}

input::placeholder {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #333333 !important;
  /* padding: 15px !important; */
}

input[type="text"] {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #333333 !important;
  resize: vertical;
  padding-left: 15px;
  /* margin-right: 15px !important; */
}

input {
  margin-right: 15px !important;
}

.report-label {
  margin-top: 10px;
}

.concept-head-button {
  padding-right: 50px !important;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
  }
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #3751ff;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #3751ff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border-bottom: none !important;
}

@media screen and (max-width: 1206px) {
  .wrong-text {
    display: none !important;
  }

  .wrong-text1 {
    display: unset !important;
  }
}

@media screen and (max-width: 1125px) {
  .wrong-text1 {
    display: none !important;
  }
}

.rotate {
  background: url("../assets/landscape.png");
  height: 100px;
  width: 100px;
  margin: 0 auto;
}

.portrait {
  display: none;
}

@media screen and (max-width: 1100px) {
  .answer-objective-div {
    width: 680px !important;
  }

  .question-image {
    max-height: 380px !important;
    max-width: 810px !important;
    min-width: 550px !important;
    border: 1px solid #f5f5f9;
  }

  .full-solution-image-2 {
    max-width: 810px !important;
    min-width: 500px !important;
    max-height: 400px;
    border: 1px solid #f5f5f9;
  }

  .concept-image-2 {
    max-height: 400px !important;
    max-width: 810px !important;
    min-width: 600px !important;
    border: 1px solid #f5f5f9;
  }
}

@media screen and (max-width: 1040px) {
  .concept-modal {
    max-width: 800px !important;
  }

  .concept-image {
    max-width: 750px !important;
  }

  .full-solution-image {
    max-width: 750px !important;
  }

  .question-image {
    max-width: 750px !important;
  }

  .full-solution-image-2 {
    max-width: 750px !important;
    min-width: 500px !important;
    max-height: 400px;
    border: 1px solid #f5f5f9;
  }

  .concept-image-2 {
    max-height: 400px !important;
    max-width: 750px !important;
    min-width: 600px !important;
    border: 1px solid #f5f5f9;
  }
}

@media screen and (max-width: 900px) {
  .concept-button {
    width: 135px !important;
  }

  .similar-button {
    width: 135px !important;
  }

  .answer-objective-div {
    width: 670px !important;
  }

  .session-box {
    height: 690px !important;
  }

  @media screen and (max-width: 895px) {
    .concept-modal {
      max-width: 650px !important;
      height: 656px;
      min-width: 450px;
    }

    .concept-modal-button {
      font-size: 15px !important;
      height: 29px;
    }

    .earn-head {
      width: 100%;
    }

    .concept-image-2 {
      max-width: 520px !important;
      max-height: 385px !important;
    }

    .full-solution-image-2 {
      max-width: 520px !important;
      max-height: 385px !important;
    }

    .selected {
      color: #3751ff;
      font-weight: 700 !important;
      font-size: 18px;
      line-height: 23px;
      border-bottom: 2px #3751ff;
    }

    .concept-head-button {
      font-weight: 500;
      font-size: 15px;
      padding-right: 50px !important;
    }
  }

  @media screen and (max-width: 750px) {
    .chapter-name {
      font-size: 15px !important;
      line-height: 20px !important;
    }

    .class-name {
      font-size: 13px !important;
    }

    .question-image {
      max-width: 620px !important;
      min-width: 550px !important;
    }

    .image-section {
      max-width: 640px !important;
      min-width: 550px !important;
    }

    .answer-objective-div {
      width: 525px !important;
    }

    .concept-button {
      width: 115px !important;
    }

    .concept-button-1 {
      width: 115px !important;
    }

    .answer-button {
      width: 51px !important;
    }

    .full-solution-image-2 {
      max-width: 650px !important;
      min-width: 500px !important;
      max-height: 400px;
      border: 1px solid #f5f5f9;
    }

    .earn-head {
      width: 100%;
    }

    .concept-image {
      width: 396px !important;
      height: 297px !important;
    }

    .full-solution-image {
      width: 396px !important;
      height: 297px !important;
    }
  }

  @media screen and (max-width: 630px) {
    .portrait {
      display: block;
      background: #3a322f;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 1001;
      text-align: center;
      color: #ffffffe6;
      left: 0;
      padding: 0 30px;
    }

    .earn-head {
      width: 90%;
    }

    .concept-image {
      width: 396px !important;
      height: 297px !important;
    }

    .full-solution-image {
      width: 396px !important;
      height: 297px !important;
    }
  }
}
</style>
