<template>
    <div class="container font-open-sans">
        <br>
        <div><strong class="fs-20 mb-10">Revision Questions</strong></div>
        <br>
        <div class="row">
            <div class="col l2 m2 s12 pl-0 input-box">
                <select name="" id="select-input" class="browser-default pointer" @change="selectSubject($event)"
                    v-model="selectedSubjectId">
                    <option value="0" :selected="selectedSubjectId === 0">All Subjects</option>
                    <option v-for="subject in subjectFilters" :key="subject.SubjectId" :value="subject.SubjectId">
                        {{ subject.SubjectName }}
                    </option>
                </select>
            </div>
            <div class="col l3 m3 s12 pl-20 input-box">
                <select name="" id="select-input" class="browser-default pointer" @change="selectTopic($event)"
                    v-model="selectedTopicId">
                    <option value="0" :selected="selectedTopicId === 0">All Topics</option>
                    <option v-for="subject in topicsList" :key="subject.TopicId" :value="subject.TopicId">
                        {{ subject.TopicName }}
                    </option>
                </select>
            </div>
            <div class="col l3 m3 s12 pl-20 input-box">
                <select name="" id="select-input" class="browser-default pointer" @change="selectChapter($event)"
                    v-model="selectedChapterId">
                    <option value="0" :selected="selectedChapterId === 0">All Chapters</option>
                    <option v-for="subject in chaptersList" :key="subject.ChapterId" :value="subject.ChapterId">
                        {{ subject.ChapterName }}
                    </option>
                </select>
            </div>
            <div class="col l3 m3 s12 pl-20 input-box">
                <select name="" id="select-input" class="browser-default pointer" @change="selectQuestionType($event)"
                    v-model="selectedQuestionTypeId">
                    <option value="0" :selected="selectedChapterId === 0">All Questions</option>
                    <option value="1" :selected="selectedChapterId === 1">Bookmarked Questions</option>
                    <option value="2" :selected="selectedChapterId === 2">Faculty marked questions</option>
                    <option value="3" :selected="selectedChapterId === 3">System selected questions</option>
                </select>
            </div>
            <div class="col l1 m1 s12 pl-20 input-box">
                <button class="test-btn w-100"
                    style="background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%); padding: 13px 10px;"
                    @click="clearFilters()">Clear</button>
                </div>
        </div>
        <div class="scroll-verticle-bar scroll-bar" v-if="listLength > 0">
            <div class="practice-details row s12 m12" v-for="data , index in tutorQnsList" :key="data.RevisionQuestionId">
                <div class="m-0">
                    <div class="title-row flex flex-between items-center display-block">
                        <div class=" fs-16 fw-600 truncate" :title="data.KSCText">{{ data.KSCText }}</div>
                    </div>
                </div>
                <div class="row flex m-0 display-block">
                    <div class="col s12 m12 l10 padding-lr-20 m-0 border-right row">
                        <div class="col m6 s12 text-div p-0 truncate margin-bottom-10" :title="data.Topic">
                            <img :src="require(`../assets/testHistory-openBook.svg`)" class="icon-bullet">
                            <span class="fw-500 fs-14 pl-5">{{ data.TopicName !== undefined ?
                                formatString(data.TopicName) : null }}</span>
                        </div>
                        <div class="col m6 s12 text-div p-0 truncate margin-bottom-10" :title="data.ChapterName">
                            <img :src="require(`../assets/testHistory-bookmark.svg`)" class="icon-bullet">
                            <span class="fw-500 fs-14 pl-5">{{ data.ChapterName !== undefined ?
                                formatString(data.ChapterName) : null }}</span>
                        </div>
                        <!-- </div> -->
                    </div>
                    <div class="col s12 m6 l2 p-0 flex flex-around">
                        <!-- <div class="flex flex-end items-center action-icons"> -->
                        <p class="flex fs-14 fw-600 light-gray-text tooltip pointer flex items-center"
                            style="height: 23px;margin-top: 0px !important"
                            @click="removeQuestion(data.RevisionQuestionId)">
                            <img :src="require(`../assets/delete-new-icon.svg`)" alt="" style="width:21px">
                            <span class="tooltiptext">Remove</span>
                        </p>
                        <p class="tooltip flex items-center" data-tooltip="I am a tooltip"
                            style="height: 23px;margin-top: 0px !important" @click="viewQuestion(data, index)">
                            <span class="material-icons material-symbols-outlined pointer">visibility</span>
                            <span class="tooltiptext">View</span>
                        </p>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-center error-box">
            <img :src="require(`../assets/no-result-found.png`)" alt="" style="width: 200px; opacity: 0.7;">
            <p class="fw-400 fs-15" style="margin-top: -20px; color: #8c8585;">No revision questions available.</p>
        </div>
        <div v-if="listLength > 0" class="pagination flex flex-between d-block">
            <div>
                <span class="show-index fw-500 fs-15  ws-nowrap ">
                    <span>Showing </span>
                    <span>{{ tutorQnsList.length }}</span>
                    <span> Revision Questions</span>
                </span>
            </div>
            <!-- <div class="flex flex-wrap margin-bottom-10-325" style="gap: 3px;">
                <a @click="previousPage()" class="navigation-btn prev-btn" :class="{ disable: currentPage < 2 }"
                    title="Previous">
                    <span class="material-icons material-symbols-outlined fs-20 pointer">west</span>
                </a>
                <a @click="nextPage()" class="navigation-btn" :class="{ disable: listLength < 20 }" title="Next">
                    <span class="material-icons material-symbols-outlined fs-20 pointer">east</span>
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
import {
  useToast,
} from "vue-toastification";
import MobileAPI from "../Mobileapi";

const toast = useToast();

export default {
  data() {
    return {
      tutorQnsList: [],
      typeTest: 1,
      courseId: null,
      getUser: null,
      startEnable: false,
      userId: null,
      affiliationUserName: null,
      currentPage: 1,
      listLength: 0,
      totalQnsList: 0,
      startPageNumber: 1,
      endPageNumber: 20,
      totalCount: 0,
      next: true,
      filterTriggered: false,
      subjectList: [],
      subjectFilters: "",
      orginalApiData: "",
      selectedSubjectId: 0,
      subjectsList: "",
      selectedTopicId: 0,
      selectedChapterId: 0,
      topicsList: "",
      chaptersList: "",
      selectedQuestionTypeId: 0,
      sourceTypeId: 0,
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  },
  created() {
    this.reset();
  },
  methods: {
    nextPage() {
      this.next = true;
      this.currentPage += 1;
      this.startPageNumber = this.endPageNumber + 1;
      this.endPageNumber += 20;
      this.getRevisionQuestionsList();
    },
    previousPage() {
      this.next = false;
      this.currentPage -= 1;
      this.endPageNumber -= this.listLength;
      this.startPageNumber -= 20;
      this.getRevisionQuestionsList();
    },
    getRevisionQuestionsList() {
      const user = this.$store.getters.user;
      const dataList = {
        SourceTypeId: 0,
        SubjectId: this.selectedSubjectId,
        TopicId: this.selectedTopicId,
        ChapterId: this.selectedChapterId,
        UserId: user.UserId,
        QuestionRevisionType: this.selectedQuestionTypeId,
        PageNo: this.currentPage,
      };
      this.$store.dispatch("showLoader", true);
      if (!this.next) {
        this.totalCount -= this.listLength;
      }
      MobileAPI.getReviseQuestions(dataList, (resp) => {
        if (resp.responseCode === 200) {
          if (resp.data.length > 0) {
            this.$store.dispatch("showLoader", false);
            this.listLength = 0;
            this.tutorQnsList = resp.data;
            this.orginalApiData = resp.data;
            this.listLength = this.tutorQnsList.length;
          } else {
            toast.error("No revision questions found.", {
              timeout: 2500,
            });
            this.tutorQnsList = [];
            this.listLength = 0;

            this.$store.dispatch("showLoader", false);
          }
        } else {
          toast.error(`${resp.data.info}`, {
            timeout: 2500,
          });
          this.$store.dispatch("showLoader", false);
        }
      });
    },
    selectSubject(event) {
      this.selectedSubjectId = Number(event.target.value);
      if (this.selectedSubjectId > 0) {
        this.topicsList = this.subjectsList.filter((a) => a.SubjectId === this.selectedSubjectId)[0].topicsList;
        this.chaptersList = "";
        this.selectedChapterId = 0;
        this.selectedTopicId = 0;
      } else {
        this.topicsList = "";
        this.selectedTopicId = 0;
        this.chaptersList = "";
        this.selectedChapterId = 0;
      }
      this.getRevisionQuestionsList();
    },
    selectTopic(event) {
      this.selectedTopicId = Number(event.target.value);
      if (this.selectedTopicId > 0) {
        this.chaptersList = this.topicsList.filter((a) => a.TopicId === this.selectedTopicId)[0].chapterList;
        this.selectedChapterId = 0;
      } else {
        this.chaptersList = "";
        this.selectedChapterId = 0;
      }
      this.getRevisionQuestionsList();
    },
    selectChapter(event) {
      this.selectedChapterId = Number(event.target.value);
      if (this.selectedChapterId <= 0) {
        this.chaptersList = "";
        this.selectedChapterId = 0;
      }
      this.getRevisionQuestionsList();
    },
    selectQuestionType(event) {
      this.selectedQuestionTypeId = Number(event.target.value);
      this.getRevisionQuestionsList();
    },
    formatString(string) {
      const str = string.split(",");
      if (str.length === 1) {
        if (str[0].length > 20) {
          str[0] = str[0].slice(0, 20);
          return `${str[0]}..`;
        }
        return str[0];
      }
      if (str[0].length > 20) {
        str[0] = str[0].slice(0, 20);
      }
      return `${str[0]}... +${str.length - 1}`;
    },
    viewQuestion(data, index) {
      this.$store.dispatch("showLoader", false);
      this.$router.push({
        name: "QuestionView",
        params: {
          questionList: JSON.stringify(this.tutorQnsList),
          questionId: data.QuestionId,
          index,
          isTutorHelp: 0,
        },
      });
    },
    removeQuestion(revisionQuestionId) {
      const data = {
        reviseQuestionId: revisionQuestionId,
        usertestSessionQuestionId: 0,
      };
      MobileAPI.deleteBookMarkedQuestions(data, (response) => {
        if (response != null && response.responseCode === 200) {
          toast.error("Revision question deleted successfully.", {
            timeout: 2500,
          });
          this.getRevisionQuestionsList();
        }
      });
    },
    clearFilters() {
      this.selectedChapterId = 0;
      this.selectedSubjectId = 0;
      this.selectedTopicId = 0;
      this.selectedQuestionTypeId = 0;
      this.topicsList = [];
      this.chaptersList = [];
      this.getRevisionQuestionsList();
    },
    reset() {
      const user = this.$store.getters.user;
      const userId = Number(user.UserId);
      this.$store.dispatch("showLoader", true);
      MobileAPI.getStudentDetails(userId, (response) => {
        if (Object.keys(response.data).length !== 0 || response.data !== null) {
          const student = response.data;
          this.courseId = student.courseId;
          this.studentId = student.studentId;
          const obj = {
            onlyCourses: 0,
            courseId: this.courseId,
          };
          MobileAPI.getCourseMetaData(user.AffiliationId, obj, (courseResponse) => {
            this.subjectsList = courseResponse.data[0];
            this.subjectFilters = this.subjectsList;
            this.batchList = courseResponse.data[1];
            console.log("subjects", this.subjectsList);
          });
          this.getRevisionQuestionsList();
        } else {
          toast.error(`${response.info}`, {
            timeout: 2500,
          });

          this.$store.dispatch("showLoader", false);
        }
      });
    },
  },
};
</script>

<style scoped>
.ml-0 {
    margin-left: 0% !important;
}

.scroll-bar {
    max-height: calc(100vh - 265px);
}

.error-box {
    margin-bottom: 20px;
}

.pagination {
    padding: 13.15px;
    background-color: #FFFFFF;
    align-items: center;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tooltip {
    position: relative;
}

.tooltiptext {
    visibility: hidden;
    background-color: #3fbbb9;
    color: white;
    text-align: center;
    padding: 2px 8px;
    border-radius: 3px;
    z-index: 1;
    white-space: break-spaces;
    text-align: center;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    transition: .3s all ease;
    font-weight: 600;
    position: absolute;
    left: -15px;
    bottom: -32px;
}

.test-btn{
    font-weight: 600;
font-size: 14px;
color: white;
border-radius: 5px;
border: none;
padding: 9px 13px;
cursor: pointer;
}
input[type=text]{
    border: 1px solid #E0E4F0 !important;
    background-color: white !important;
    height: 43px;
    border-radius: 4px;
    padding: 2px 10px;
    margin: 0;
}
input[type=text]:not(.browser-default):focus:not([readonly]){
    box-shadow: none;
}

.tooltiptext::before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: "";
    background: #3fbbb9;
    left: 42%;
    top: -5px;
    -webkit-backdrop-filter: #3fbbb9;
    backdrop-filter: #3fbbb9;
    transform: rotate(45deg);
    background: #3fbbb9;
    visibility: hidden;
    transition: .1s all ease;
    line-height: 20px;
}

.tooltip:hover .tooltiptext,
.tooltip:hover .tooltiptext::before {
    visibility: visible;
}

.pagination {
    margin-bottom: 10px;
}

.prev-btn {
    margin-right: 20px;
}

.show-index {
    color: #333333;
}

.br-4 {
    border-radius: 4px;
}

.padding-lr-20 {
    padding: 0px 20px !important;
}

.border-right {
    height: max-content;
    border-right: 1px solid #D0D0D0;
}

.pl-25 {
    padding-left: 25px !important;
}

.p-20 {
    padding: 20px !important;
}

.action-icons {
    justify-content: flex-start;
    margin-top: 7px;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.btn-last {
    text-align: end;
    margin-top: 5px;
}

.title-row {
    margin-right: 14px;
    padding: 15px 20px;
}

.font-open-sans {
    font-family: 'Open Sans';
    font-style: normal;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.m-0 {
    margin: 0 !important;
}

.c-blue {
    color: #3751FF;
}

.c-green {
    color: #16A085;
}

.c-red {
    color: #EF727A;
}

.mb-10 {
    margin-bottom: 8px;
}

.radio-btn {
    color: black;
    font-weight: 400;
    margin-right: 10px;
    margin-top: 8px;
}

.icon-bullet {
    width: 24px;
}

.flex-justify-space-around {
    display: flex;
    justify-content: space-around;
}

.flex-direction-align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pl-0 {
    padding-left: 0px !important;
}

.pl-5 {
    padding-left: 5px;
}

.pl-25 {
    padding-left: 25px !important;
}

.pr-10 {
    padding-right: 10px;
}

.text-div {
    display: flex;
    align-items: center;
}

.text-500 {
    font-weight: 500px;
    font-size: 14px;
}

.test {
    background-color: #338510;
}

.practice-details {
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px;
}

.submit-btn {
    gap: 20px;
    display: flex;
}

.browser-default {
    background: #F3F5FC;
    border-radius: 4px !important;
    border-color: #F3F5FC !important;
    height: 34px;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:before,
[type="radio"].with-gap:checked+span:after {
    border: 2px solid #3751FF;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:after {
    background-color: #3751FF;
}

.lblTest {
    margin-left: 10px;
}

.testHLinkButton {
    width: 139px;
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
    border-radius: 5px;
    text-transform: uppercase;
    border: 1px solid #FF9421;
    height: 35px;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 600px;
    cursor: pointer;
}

.testHLinkButton:hover {
    cursor: pointer;
}

.getbutton {
    background: linear-gradient(90.2deg, #3751FF 0.16%, #3751FF 98.41%);
    border-radius: 5px;
    width: 150px;
}

.scroll-verticle,
.scroll-verticle-bar {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    white-space: nowrap !important;
}

.scroll-verticle::-webkit-scrollbar {
    width: 0px;
}

.scroll-verticle-bar::-webkit-scrollbar {
    width: 5px;
}

.scroll-verticle::-webkit-scrollbar-track,
.scroll-verticle-bar::-webkit-scrollbar-track {
    background-color: #ececec;
    border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb,
.scroll-verticle-bar::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.navigation-btn.disable {
    pointer-events: none;
}

.navigation-btn {
    width: 30px;
    height: 30px;
    line-height: 38px;
    text-align: center;
    background-color: white;
    color: #8c8585;
    border: 1px solid #333333 !important;
    border-radius: 3px;
}

.navigation-btn.currentPage {
    border-color: #3751FF !important;
    color: #3751FF;
}

.input-box {
    padding-right: 0 !important;
}

select {
    border: 1px solid #E0E4F0 !important;
    background-color: white !important;
    height: 43px;
    border-radius: 4px;
    font-size: 14px;
    color: #adadad;
}

#select-input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("../assets/Vector4.svg") no-repeat right;
    background-size: 38px 6px;
    height: 43px;
    padding-left: 7px;
}

@media screen and (max-width: 1120px) {
    .display-block {
        display: block !important;
    }

    .margin-tb-10-1120 {
        margin: 10px 0px !important;
    }
}

@media screen and (max-width: 600px) {
    .flex-align-start {
        align-items: flex-start;
    }

    .left-align-wht {
        width: 26px;
        height: 26px;
        text-align: center;
    }

    .padding-0-600 {
        padding: 0px !important;
    }

    .padding-l-20 {
        padding-left: 20px !important;
    }

    .margin-bottom-0 {
        margin-bottom: 0px !important;
    }

    .margin-bottom-10 {
        margin-bottom: 10px !important;
    }

    .text-align-left {
        text-align: left !important;
    }
}

@media screen and (max-width: 325px) {
    .d-block {
        display: block !important;
    }

    .padding-l-0 {
        padding-left: 0px !important;
    }

    .margin-bottom-10-325 {
        margin-bottom: 10px !important;
    }

    .ws-nowrap {
        white-space: nowrap !important;
    }
}
</style>
